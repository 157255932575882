import { Grid } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Navbar from '../../app/components/Navbar';
import AccordionItem from '../../app/components/AccordionItem';
import HslButton from '../../forms/components/HslButton';
import Footer from '../../app/components/Footer';
import ContentBlock from './ContentBlock';
import { pricesUrl } from '../../docs/constants';
import { signupUrl } from '../../util/constants';
import HeroJpg from '../../static/images/hero.jpg';
import PricingSvg from '../../static/icons/pricing.svg';
import MobileSvg from '../../static/icons/mobile.svg';
import TicketsSvg from '../../static/icons/tickets.svg';
import LaptopSvg from '../../static/icons/laptop.svg';
import TwitterSvg from '../../static/icons/twitter.svg';
import FaqSvg from '../../static/icons/faq.svg';

const LanderPage = () => (
  <Container>
    <Helmet>
      <title>HSL - OpenMaaS Developer Portal</title>
      <meta name="description" content="HSL OpenMaaS is an open-for-all ticket sales interface (API) for acquiring HSL mobile tickets." />
      <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8" />
    </Helmet>

    <Navbar />

    <main>
      <HeroContainer>
        <HeroInfoContainer>
          <h1>Integrate HSL public transport tickets into your app</h1>
          <p>Helsinki Region Transport (HSL) supports the creation of new innovative services through its open data policy. HSL OpenMaaS is an open-for-all ticket sales interface for acquiring HSL mobile tickets.</p>
          <HeroButtonContainer>

            <HeroButtonAnchor rel="noopener noreferrer" target="_blank" href="/ticket-api-doc">
              <HeroButton inverse>API documentation</HeroButton>
            </HeroButtonAnchor>

            <HeroButtonAnchor rel="noopener noreferrer" target="_blank" href="/info">
              <HeroButton inverse>Information</HeroButton>
            </HeroButtonAnchor>

            <HeroButtonAnchor rel="noopener noreferrer" target="_blank" href="/whats-new">
              <HeroButton inverse>What's New</HeroButton>
            </HeroButtonAnchor>

            <HeroButtonAnchor rel="noopener noreferrer" target="_blank" href={signupUrl}>
              <HeroButton primary>Sign up</HeroButton>
            </HeroButtonAnchor>

          </HeroButtonContainer>
        </HeroInfoContainer>
        <HeroImageContainer>
          <HeroImage>
            <img
              style={{
                width: '100%',
                visibility: 'hidden',
              }}
              src={HeroJpg}
              alt="hero"
            />
          </HeroImage>
        </HeroImageContainer>
      </HeroContainer>

      <GreyBg>
        <TextContainer>
          <QuadrantContainer>
            <ContentBlock headingText="Pricing" icon={PricingSvg}>
              <QuadrantTextUnorderedList>
                <li>
                  The HSL OpenMaaS API is free of charge to use.
                </li>
                <li>
                  HSL does not pay a commission or other compensation to HSL OpenMaas API users for selling tickets.
                </li>
                <li>
                  The ticket prices are the same as on HSL’s Travel Card and mobile channels. Please see the
                  {' '}
                  <a className="hslLink" rel="noopener noreferrer" target="_blank" href={pricesUrl}>price endpoint</a>
                  . You may freely price the tickets to your users.
                </li>
              </QuadrantTextUnorderedList>
            </ContentBlock>
          </QuadrantContainer>
          <QuadrantContainer>
            <ContentBlock headingText="Ticket availability" icon={TicketsSvg}>
              <QuadrantTextUnorderedList>
                <li>
                  Single tickets (adult, child 7-17 yrs)
                </li>
                <li>
                  1-13 day tickets (adult, child 7-17 yrs)
                </li>
                <li>
                  Zone extension tickets (adult, student, child 7-17 yrs)
                </li>
                <li>
                  14, 30, 60, 90, 120, 150, 180, 210, 240, 270 and 360 day season tickets (adult, child 7-17 yrs, senior 70+)
                </li>
                <li>
                  14 and 30 day season tickets (student)
                </li>
                <li>
                  Auto-renewing saver subscription (adult)
                </li>
              </QuadrantTextUnorderedList>
            </ContentBlock>
          </QuadrantContainer>
          <QuadrantContainer>
            <ContentBlock headingText="Developers – ready, steady, go!" icon={LaptopSvg}>
              <QuadrantText>
                As an OpenMaaS operator you can integrate into the HSL OpenMaaS API and create a platform through which you can make HSL mobile tickets available to your own customers. In this portal you can sign up and log in to manage your organizations’s API keys and payment details.
              </QuadrantText>
            </ContentBlock>
          </QuadrantContainer>
          <QuadrantContainer>
            <ContentBlock headingText="Implementing HSL mobile tickets" icon={MobileSvg}>
              <QuadrantTextOrderedList>
                <li>
                  Start the contract process with HSL
                </li>
                <li>
                  Create your own application
                </li>
                <li>
                  Order tickets from the HSL OpenMaas API using your API key
                </li>
                <li>
                  Use the ticket ID provided to get the html ticket from the render endpoint
                </li>
                <li>
                  Display the ticket in your own application
                </li>
              </QuadrantTextOrderedList>
              <p>
                Examples are provided in the
                <a className="hslLink" rel="noopener noreferrer" target="_blank" href="/ticket-api-doc">API documentation.</a>
              </p>
            </ContentBlock>
          </QuadrantContainer>
        </TextContainer>
      </GreyBg>
      <Grid container centered stackable>
        <Grid.Column width={12}>
          <ContentBlock headingText="Tweets" icon={TwitterSvg}>
            <a className="twitter-timeline" data-chrome="noheader nofooter noborders" data-theme="light" data-tweet-limit="3" href="https://twitter.com/HSLdevcom">Tweets by HSLdevcom</a>
          </ContentBlock>
        </Grid.Column>
        <ContractTextColumn textAlign="center" width={16}>
          <ContractTextContainer>
            <h2>API contract</h2>
            <p>
              Using the HSL OpenMaaS API requires a contract between you and HSL.
              For more information on the contract, please contact Risto Laine
              <br />
              <a className="hslLink" href="mailto:risto.k.laine@hsl.fi">risto.k.laine@hsl.fi</a>
              .
            </p>
          </ContractTextContainer>
        </ContractTextColumn>
        <Grid.Column width={14}>

          <ContentBlock headingText="FAQ: HSL OpenMaaS API" icon={FaqSvg}>

            <AccordionItemFirst title="Which are the HSL municipalities and which neighbouring municipalities?">
              <p>
                The HSL area covers Helsinki, Espoo, Vantaa, Kauniainen, Siuntio, Kirkkonummi, Sipoo, Kerava and Tuusula. Neighbouring municipalities are Hanko, Hausjärvi, Inkoo, Järvenpää, Karkkila, Lohja, Loppi, Mäntsälä, Nurmijärvi, Pornainen, Porvoo, Pukkila, Raasepori and Vihti.
              </p>
            </AccordionItemFirst>
            <AccordionItem title="Can the end-customer pay for his/her tickets directly to HSL instead of the API user (i.e. MaaS operator)?">
              <p>
                HSL OpenMaaS API is a B2B API, so it’s always the API user who pays for the tickets.
              </p>
            </AccordionItem>
            <AccordionItem title="Does an API user need to sign a separate contract with HSL to use the HSL OpenMaaS API?">
              <p>
                You need to sign a contract with HSL to use the HSL OpenMaaS API.
              </p>
            </AccordionItem>
            <AccordionItem title="Which ticket types require the end-customer to give authorization for “on behalf purchasing” to the API user via the HSL authorisation UI and which not?">
              <p>
                The API user can purchase single and 1-13 day tickets without applying the “on behalf purchasing” process.
                <br />
                <br />
                For personal, subsidized tickets, the end-customer is required to authorize the API user to purchase tickets on his or her behalf. These are currently: all season tickets for adults, children aged 7-17, passengers aged 70+ and students living the nine HSL municipalities (Helsinki, Espoo, Vantaa, Kauniainen, Siuntio, Kirkkonummi, Sipoo, Kerava and Tuusula) and adults living in 13 neighbouring municipalities (Hanko, Hausjärvi, Inkoo, Järvenpää, Karkkila, Lohja, Loppi, Mäntsälä, Nurmijärvi, Pornainen, Porvoo, Pukkila, Raasepori and Vihti) and zone extension tickets to these.
              </p>
            </AccordionItem>
            <AccordionItem title="How are the tickets purchased via the HSL OpenMaaS API paid for?">
              <p>
                HSL OpenMaaS API is a B2B API. HSL is not in the position to check the financial position of the HSL OpenMaaS API users. Consequently, payment must be made before the journey takes place. In practice, this means that mobile tickets purchased via the interface are charged for by using the “OpenMaaS wallet” of the API user. Top ups to the organization-specific “OpenMaaS wallet” are made by bank transfers
              </p>
            </AccordionItem>
            <AccordionItem title="What kind of sales reporting is available for the HSL OpenMaaS API user?">
              <p>
                The HSL OpenMaaS API user can order monthly sales reports on all transactions in their “OpenMaaS wallet”.  This is done by adding an email address of the recipient of the report in the Payments section of the Developer portal.
              </p>
            </AccordionItem>
            <AccordionItem title="Who is responsible for end-user customer service?">
              <p>
                The HSL OpenMaaS API user is responsible for providing customer service for its own customers.
                The API user will direct to HSL any feedback it receives concerning HSL.
              </p>
            </AccordionItem>
            <AccordionItem title="How is ticket visualisation created?">
              <p>
                After the ticket purchase the HSL OpenMaaS API returns a ticket id. The ticket id is used to fetch
                the html-code of the ticket. The ticket visualization is created for the HSL OpenMaaS API user.
              </p>
            </AccordionItem>
            <AccordionItem title="Can an API user organization have several users with separate user names?">
              <p>
                Yes. Additional users can be invited in the developer portal in sub tab Organization Profile.
              </p>
            </AccordionItem>
          </ContentBlock>
        </Grid.Column>
      </Grid>
    </main>

    <Footer />
  </Container>
);

export default LanderPage;

const HeroButton = styled(HslButton)`
  width: 100%;
  margin: 0;
`;

const HeroButtonAnchor = styled.a`
  margin: 0 10px 10px 0;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-width: 601px) {
    width: 264px;
  }
}
`;

const HeroContainer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  @media only screen and (min-width: 991px) {
    width: 75%;
    max-width: 1600px;
    margin: auto;
  }
  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`;

const HeroImageContainer = styled.div`
  display: flex;
  width: 50%;
  @media only screen and (max-width: 720px) {
    width: 100%;

    & .heroImage {
      background-image: url(${HeroJpg});
    }
  }
}
`;

const HeroInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;width: 50%;
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
}
`;

const HeroImage = styled.div`
  background-image: url(${HeroJpg});
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 10px;
`;

const HeroButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
`;

const ContractTextColumn = styled(Grid.Column)`
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
`;

const Container = styled.div`
  width: 100%;

  & h1 {
    font-family: var(--font-header-book);
    font-size: 30px;
    font-weight: 700;
  }

  & h2 {
    font-family: var(--font-header-book);
    font-weight: 700;
  }

  & header {
    margin: 0;
  }

  & p,
  & li {
    font-family: var(--font-header-light);
    font-size: 18px;
  }
`;

const ContractTextContainer = styled.div`
  max-width: 1600px;
  padding-bottom: 45px;
  display: flex;
  width: 75%;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 0;
  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`;

const AccordionItemFirst = styled(AccordionItem)`
  margin-top: 40px;
`;

const TextContainer = styled.div`
  max-width: 1600px;
  padding-bottom: 45px;
  display: flex;
  width: 75%;
  margin: auto;
  flex-wrap: wrap;
  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`;

const QuadrantContainer = styled.div`
  padding: 20px;
  min-width: 300px;
  width: 50%;

  & li {
    margin-top: 10px;
  }

  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
}
`;

const GreyBg = styled.div`
  background-color: var(--hsl-footer-background);
`;

const QuadrantTextUnorderedList = styled.ul`
  margin-top: 25px;
  max-width: 600px;
  padding: 0 0 0 15px;
  margin: 0 0 30px;

  & > li {
    margin: 10px 0;
    line-height: 1.4;
  }
`;

const QuadrantTextOrderedList = styled.ol`
  margin-top: 25px;
  max-width: 600px;
  padding: 0 0 0 15px;
  margin: 0 0 30px;

  & > li {
    margin: 10px 0;
    line-height: 1.4;
  }
`;

const QuadrantText = styled.p`
  margin-top: 25px;
  max-width: 600px;
`;
