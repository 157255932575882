/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import InfoPage from './components/InfoPage';
import WhatsNewPage from './components/WhatsNewPage';
import ApiDoc from './components/ApiDoc';
import ticketApiDoc from './json/ticketApiDoc.json';
import supersaverApiDoc from './json/supersaverApiDoc.json';
import creditApiDoc from './json/creditApiDoc.json';
import userApiDoc from './json/userApiDoc.json';

export default [
  <Route key="docs_info" path="/info" component={InfoPage} />,
  <Route key="docs_whats-new" path="/whats-new" component={WhatsNewPage} />,
  <Route key="docs_public-docs" path="/public-docs" render={() => <Redirect to="/info" />} />,
  <Route key="docs_api-doc" path="/api-doc" render={() => <Redirect to="/ticket-api-doc" />} />,
  <Route key="docs_api-doc2" path="/api-doc2" render={() => <Redirect to="/ticket-api-doc2" />} />,
  <Route key="docs_ticket-api-doc" exact path="/ticket-api-doc" render={(props) => <ApiDoc {...props} apiDoc={ticketApiDoc} />} />,
  <Route key="docs_supersaver-api-doc" exact path="/supersaver-api-doc" render={(props) => <ApiDoc {...props} apiDoc={supersaverApiDoc} />} />,
  <Route key="docs_credit-api-doc" exact path="/credit-api-doc" render={(props) => <ApiDoc {...props} apiDoc={creditApiDoc} />} />,
  <Route key="docs_user-api-doc" exact path="/user-api-doc" render={(props) => <ApiDoc {...props} apiDoc={userApiDoc} />} />,
];
