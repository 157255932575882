import React from 'react';
import { Grid, List } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Navbar from '../../app/components/Navbar';
import Footer from '../../app/components/Footer';
import changelogEntries from '../json/changelogEntries.json';

const parseBoldText = (text: string) => {
  const parts = text.split(/(\*\*.*?\*\*)/g);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    return part;
  });
};

const WhatsNewPage = () => (
  <Container>
    <Helmet>
      <title>What's New - HSL OpenMaaS Developer Portal</title>
      <meta name="description" content="Latest updates and changes to the HSL OpenMaaS API and Developer Portal." />
    </Helmet>

    <Navbar />
    <main>
      <ListContainerGrid container>
        <div>
          <List divided verticalAlign="middle" size="big" relaxed>
            <List.Item>
              <h2>What's New</h2>
            </List.Item>
            {changelogEntries.map((entry, index) => (
              <List.Item key={index}>
                <ChangelogEntry>
                  <ChangelogDate>{entry.date}</ChangelogDate>
                  <ChangelogTitle>{entry.title}</ChangelogTitle>
                  <ChangelogDescription>{parseBoldText(entry.description)}</ChangelogDescription>
                </ChangelogEntry>
              </List.Item>
            ))}
          </List>
        </div>
      </ListContainerGrid>
    </main>
    <Footer />
  </Container>
);

export default WhatsNewPage;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .ui.list {
    margin-top: 0;
  }
`;

const ListContainerGrid = styled(Grid)`
  min-height: 60vh;
`;

const ChangelogEntry = styled.div`
  margin-bottom: 20px;
`;

const ChangelogDate = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
`;

const ChangelogTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--hsl-blue);
`;

const ChangelogDescription = styled.p`
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-wrap;
`;
