import { generate } from 'node-iso11649';

/**
 * Generates an RF (Reference Number) code based on a given HSL customer ID.
 * This function utilizes the node-iso11649 library to ensure the generated RF codes
 * are compliant with the ISO 11649:2009 standard, which is widely recognized in international
 * financial transactions.
 *
 * Standards and Compliance:
 * - ISO 11649:2009: Specifies the structure and generation algorithm for creditor reference
 *   numbers, ensuring uniqueness and traceability. It includes a prefix "RF", followed by
 *   two check digits calculated using a modulo 97 operation as per ISO/IEC 7064.
 *
 * - Finnish Banking Standards: The generation process incorporates the Finnish checksum method,
 *   which uses a cyclic weight set of 7, 3, 1 applied to the digits of the number from right to left.
 *   This checksum is crucial for the integrity of reference numbers in Finnish banking and invoicing,
 *   making the RF codes fully compatible with Finnish financial systems.
 *
 * Usage:
 * The 'pretty' option in the generate function formats the RF code into groups of four characters
 * for better readability, making it easier to read and manually process if necessary.
 *
 * @param {string} customerId - The HSL customer ID from which the RF code will be generated.
 * @returns {string} The formatted RF code.
 */
export function generateRFCode(customerId: string): string {
  const rfCode = generate(customerId, { pretty: false });
  return rfCode;
}
