/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import styled from 'styled-components';
import { Field } from 'redux-form';
import { StoreState } from '../../state/types';
import adminUpdateCreditAccount from '../sagas/adminUpdateCreditAccount';
import { updateReferenceCode } from '../sagas/updateReferenceCode';
import { asForm, runSaga } from '../../util';
import { selectActiveOrgId } from '../../organization/selectors';
import { selectCreditAccount } from '../selectors';
import InputField from '../../forms/components/InputField';
import HslButton from '../../forms/components/HslButton';
import Toast from '../../app/components/Toast';
import ToggleSwitch from '../../forms/components/ToggleSwitch';
import { generateRFCode } from '../../util/rfCodeGenerator';

const onSubmit = ({
  organizationId,
  hslCustomerId,
  referenceCode,
  allowNegativeCredit,
}: {
  organizationId: string;
  hslCustomerId: number;
  referenceCode: string[];
  allowNegativeCredit: boolean;
}) => {
  runSaga(adminUpdateCreditAccount, {
    organizationId,
    hslCustomerId,
    referenceCode,
    allowNegativeCredit,
  });
};

const requiredIfNotAllowNegative = (value: string | number | boolean, allValues: any) => {
  if (allValues.allowNegativeCredit) return undefined;
  return (value ? undefined : 'Required field');
};

const AdminCreditBox = ({
  handleSubmit,
  submitting,
  submitFailed,
  submitErrors,
  submitSucceeded,
  initWithValues,
  invalid,
  fieldValues,
}: {
  handleSubmit: (values: any) => React.FormEventHandler<HTMLFormElement>;
  submitting?: boolean;
  submitFailed?: boolean;
  submitErrors: any;
  submitSucceeded?: boolean;
  initWithValues: () => {};
  invalid?: boolean;
  fieldValues: any;
}) => {
  const handleUpdateReferenceCode = () => {
    if (fieldValues.hslCustomerId) {
      const newCode = generateRFCode(fieldValues.hslCustomerId.toString());
      runSaga(updateReferenceCode, newCode);
    } else {
      console.error('No HSL Customer ID available for generating RF Code');
    }
  };
  return (
    <KpiContainer>
      <h2>Administrative settings</h2>
      <form onSubmit={handleSubmit(onSubmit)}>

        <Field
          label="Reference code"
          id="referenceCode"
          name="referenceCode"
          component={InputField}
        />

        <Field
          label="HSL customer ID"
          id="hslCustomerId"
          name="hslCustomerId"
          component={InputField}
          validate={[requiredIfNotAllowNegative]}
          disabled={fieldValues.allowNegativeCredit}
        />

        <ToggleSwitch
          name="allowNegativeCredit"
          id="allowNegativeCredit"
          onLabel="Invoice"
          offLabel="Wallet"
        />

        <ButtonContainer>
          <HslButton type="button" onClick={handleUpdateReferenceCode} color="grey">Gen. RF Code</HslButton>
          <HslButton loading={submitting} disabled={submitting || invalid} type="submit" color="blue">Submit</HslButton>
        </ButtonContainer>
      </form>
      <Toast error={submitFailed} message={submitErrors.general || (submitSucceeded && 'Updated')} onClose={initWithValues} />
    </KpiContainer>
  );
};

export default asForm('creditInfo', {
  mapStateToProps: (state: StoreState) => ({
    initialValues: selectCreditAccount(state, selectActiveOrgId()),
  }),
})(AdminCreditBox);

const KpiContainer = styled.div`
  height: 100%;
  text-align: initial;
  background: rgb(245, 245, 245);
  padding: 20px 30px;
  border-radius: 12px;
  border: 2px solid #e4e4e4;
  #referenceCode:disabled {
    color: rgb(245, 245, 245) !important;
  }
  #hslCustomerId:disabled {
    color: rgb(245, 245, 245) !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
